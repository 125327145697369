<template>
  <div class="container">
    <!-- 导航 -->
    <van-nav-bar left-arrow title="快速会议" @click-left="onClickBackHandle"/>

    <!-- 表单 -->
    <van-cell-group class="group">
      <van-cell title="开启视频" center>
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="set.isEnableVideo" />
      </van-cell>
    </van-cell-group>

    <van-cell-group class="group" v-if="supportFace">
      <van-cell title="开启美颜" center>
          <van-switch class="switch" @change="onChangeFace" active-color="#1AB370" inactive-color="#d9d9d9" v-model="set.isEnableFace" />
      </van-cell>
      <van-cell center v-if="set.isEnableFace" @click="showFaceSetting = true">
        <div class="set-item">
          <span class="label">{{$t('login.openSetFace')}}</span>
          <my-icon fontSize="28" class="arrow" iconName="icon-jiantou_zuo_48" />
        </div>
      </van-cell>
    </van-cell-group>

    <van-cell-group class="group">
       <van-cell title="使用个人固定会议号" center class="auto-cell">
          <van-switch class="switch" active-color="#1AB370" inactive-color="#d9d9d9" v-model="set.isEnableNumber" />
      </van-cell>
      <van-cell center v-if="set.isEnableNumber" :value="conferenceNo | formatCodeFilter" />
    </van-cell-group>

    <!-- 业务组件 加入会议按钮--->
    <join-button class="save" 
        :userName="userName" 
        :conferenceNo="conferenceNo" 
        :isNew="true"
        @join-finish="joinFinishHandle" 
        @join-before="joinBeforeHandle"
        :active="true">进入会议</join-button>
     
     <!-- 美颜设置弹窗 -->
    <face v-if="supportFace" v-model="showFaceSetting" @close="showFaceSetting = false"></face>
  </div>
</template>

<script>
import '@/utils/filter'
import moment from 'moment'
import { strToBoolean } from '@/utils/str'
import { saveLocal, loadLocal, formatCode } from '@/utils/tool'

import JoinButton from '@/components/button/JoinButton'
import { getLocalUserInfo } from "@/utils/auth"
import Face from '@/components/Face/index'

export default {
  data() {
    return {
       set:  {
         isEnableNumber: true,
         isEnableVideo: true,
         isEnableFace: false
       },
       showFaceSetting: false,
       conferenceNo: '', 
       userName: '',
       joinParamData:{
        businessType:10006
       }
    }
  },

  components: {
    JoinButton,
    Face
  },

  computed: {
    enableFace () {
      return this.$store.state.meet.enableFace
    },

    supportFace () {
      return this.$store.state.face.support
    },
  },

  watch: {
    enableFace(value) {
      //同步设置里面美颜开关切换
      this.set.isEnableFace = value
    }
  },
  
  mounted () {
    const userInfo =  getLocalUserInfo()
    this.conferenceNo = formatCode(userInfo.conferenceNo)
    this.userName = userInfo.realName

    let isUseShiPin = loadLocal("isUseShiPin")
    this.set.isEnableVideo = strToBoolean(isUseShiPin, false)

    let isUseMeiYan = loadLocal("isUseMeiYan")
    this.set.isEnableFace = strToBoolean(isUseMeiYan, false)

    this.$store.commit("meet/updateGlobalMeetState", {
        enableFace: this.set.isEnableFace
    })
  },

  methods: {
    onClickBackHandle() {
      this.$router.push({ name: 'home'})
    },

    onChangeFace(value) {
      this.$store.commit("meet/updateGlobalMeetState", {
          enableFace: value
      })
    },

    joinBeforeHandle(cb) {
       // 新会议
      const nowDate = moment().format('YYYY-MM-DD HH:mm:ss')
      let data = {
        agenda: "", //会议议程
        agendaPermission: 0, // 会议议程权限 0：所有人可见 1：仅主持人可见
        allowEarlyEntry: 1, // 是否允许提前进入 1：是；0否；
        allowSelfUnmute: 1, // 成员加入会议静音 1：是；0否；
        beginTime: nowDate, // 开始时间
        endTime: nowDate, // 结束时间
        links: "", // 会议链接
        muteJoinMeeting: 1, // 允许成员自我解除静音;1是；0：否
        ownerId: "", // 会议主持人ID
        ownerPassword: "", // 主持人密码
        password: "", // 入会密码
        passwordEnable: 0, // 是否开启入会密码 0 关闭，默认；1 开启
        playTips: 0, // 成员加入会议时播放提示音1：是；0否；
        title: this.userName + (this.set.isEnableNumber? this.$t('seeting.personMeet') : this.$t('seeting.fastMeet')), // 会议名称
        type: 2, // 1:预约会议 2:即时会议
        useSelfConference: this.set.isEnableNumber? 1 : 0, // 是否使用个人会议号   1:是 0：否
      };
      this.handleSave(data, cb)
    },

    async handleSave(data, cb) {
      //---------------- SDK 创建会议 -------------------------
      sdk.meetingManagement.createMeeting(data)
      .then(
        res => cb && cb('', res.conferenceNo)
      ).catch(
        err => {
          this.$eventBus.$emit('join-error', err, this.handleSave, [ data, cb ])
        }
      ) 
    },

    // 加入会议完成回调
    joinFinishHandle(data) {
      const { userid } = data

      saveLocal("isUseShiPin", this.set.isEnableVideo);
      saveLocal("isUseMeiYan", this.set.isEnableFace);
 
      saveLocal(userid + "isUseMyGeRen", this.set.isEnableNumber);
    },

  },


  // beforeRouteEnter(to, from, next) {
    
  //   const exceptionExitMsgObj = localStorage.getItem('exceptionExitMsg');
  
  //   if(exceptionExitMsgObj && from.name == 'meeting') {
  //     console.log('joinButton ', to, from);
  //     window.location.reload()
  //   } else {
  //     next({})
  //   }

  // }
}
</script>

<style scoped lang="less">
.flex_center(@justify:center) { 
    display: flex;
    align-items: center;
    justify-content: @justify;
}

.container {
   background: #FAFAFA;
   width: 100%;
   height: 100%;

   .group {
     margin-top: 16px;
   }

   .group .van-cell {
     font-size: 32px;
     min-height: 104px;
     line-height: 100%;
   }
   .group .van-cell__value {
     color:#333;
     font-size: 28px;
   }

   .group .set-item {
        width: 100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        .label {
          display: inline-block;
          font-size: 28px;
          font-weight: 400;
          color: #333333;
        }
        .arrow {
          transform: rotate(180deg);
          margin-right: 20px;
        }
     }

   .switch {
      width: 96px;
      height: 48px;
    }

   .button {
     width: 622px;
     height: 88px;
     border-radius: 10px;
     font-size: 32px;
     font-weight: 500;
     border:none;
     display: block;
     margin:0 auto;
     margin-bottom: 32px;
   }
   .save {
      margin-top: 80px;
      text-align: center;
   }
   /deep/ .save .button{
     margin-bottom: 32px;
     width: 622px;
   }
}


::v-deep .van-nav-bar .van-icon {
  color:#333;
}
::v-deep .auto-cell .van-cell__title {
  flex: none;
}

/***** 组件兼容性 处理 px尚未自动转化****/
::v-deep .van-nav-bar__arrow,
::v-deep .van-cell__right-icon {
  font-size: 30px;
}
::v-deep .van-nav-bar__content {
  height: 88px;
  line-height: 88px;
}
::v-deep .van-nav-bar__title {
  font-size: 32px;
  font-weight: 400;
  color: #333333;
}
::v-deep .switch .van-switch__node {
  width: 44px;
  height: 44px;
  top: 2px;
}
::v-deep .van-switch--on .van-switch__node {
    -webkit-transform: translateX(48px);
    transform: translateX(48px);
}
::v-deep .multi-row .van-field__control {
   line-height: 40px;
}
</style>